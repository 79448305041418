.custom-vimeo {
  position: relative;
  width: 100%;
  padding-top: 56.25%; /* for 16:9 aspect ratio */
}

.custom-vimeo iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
