body {
  margin: 0;
  background: #fff;
  width: 100%;
  height: 100%;
  color: #1a242b;
  overflow: auto;
}

* {
  font-family: 'Poppins';
}

b,
strong {
  font-weight: 500;
}

.textWithIcons {
  display: flex;
  align-items: center;
}

.suspendAfterTwoLines {
  display: -webkit-box;
  text-overflow: ellipsis;
  word-wrap: break-word;
  overflow: hidden;
  white-space: pre-line;
  -webkit-box-orient: vertical;
  /* start showing ellipsis when 2nd line is reached */
  -webkit-line-clamp: 2;
}

/* remove increments for inputs */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0;
  /* <-- Apparently some margin are still there even though it's hidden */
}

input[type='number'] {
  -moz-appearance: textfield;
  /* Firefox */
}

b {
  font-weight: 600;
}

/* Tagify mentions dropdown */
.tagify__dropdown {
  border-radius: 4px;
  border-top-color: transparent;
}

.tagify__dropdown__wrapper {
  border-radius: 4px;
  border-color: #7c8db533;
}

.tagify__dropdown__item {
  font-size: 14px;
  color: rgb(26, 75, 123);
  border-radius: 4px;
}

.tagify__dropdown__item--active {
  background-color: rgb(224, 240, 255);
}

/*****************************
Begin IQVIA sign in styling
******************************/
#hca_signin {
  width: 100%;
  max-width: 400px;
}

#hca_signin button {
  background-color: #1976D4;
  color: #fff;
  border: none;
  border-radius: 20px;
  padding: 10px 20px;
  width: 100%;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  font-weight: 500;
}

#hca_signin button:hover {
  cursor: pointer;
  background-color: #1565c0;
}

/*****************************
End IQVIA sign in styling
******************************/